<script setup lang="ts">
const links = [{
    label: 'Announcements',
    to: '/announcements',
}, {
    label: 'Roadmap',
    to: '/roadmap',
}, {
    label: 'Ideas',
    to: '/ideas',
}];
</script>

<template>
    <div>
        <ClientOnly fallback="span">
            <UHeader :links="links">
                <template #logo>
                    Showyourwork <UBadge
                        label="SaaS"
                        variant="subtle"
                        class="mb-0.5"
                    />
                </template>
            </UHeader>
        </ClientOnly>

        <UMain>
            <slot />
        </UMain>

        <AppFooter />
    </div>
</template>
